import React from "react"
import icon from "./Icons.svg"

const Icon = props => (
  <svg className={`icon ${props.iconClassName ? props.iconClassName : ""}`}>
    <use
      href={`${icon}#${props.iconName}`}
      xlinkHref={`${icon}#${props.iconName}`}
    />
  </svg>
)

export default Icon
