import React from "react"
import { Link } from "gatsby"

import Icon from "../Icon/Icon"
import DiagonalSection from "../DiagonalSection/DiagonalSection"

const Footer = props => (
  <DiagonalSection className="diagonal-section-top--flip-x">
    <div className="container max-width-sm">
      <h2 className="text-md text-left font-primary-medium border-bottom border-2 border-color-key margin-bottom-md flex justify-between items-center">
        Visite nuestras oficinas
        <Icon iconName="icon-office" />
      </h2>

      <p className="flex items-baseline margin-bottom-xxxs text-sm">
        <Icon iconName="icon-location" iconClassName="margin-right-xxs" />
        <a
          href="https://goo.gl/maps/Ww2hyVWoZFMkJvhm6"
          target="_blank"
          rel="noreferrer"
          className="link">
          Paseo de San Gregorio 18
        </a>
      </p>

      <p className="flex items-baseline margin-bottom-xxxs text-sm">
        <Icon iconName="icon-webapp-globe" iconClassName="margin-right-xxs" />
        <span>Puertollano (Ciudad Real)</span>
      </p>

      <p className="flex items-center text-sm">
        <Icon iconName="icon-webapp-phone" iconClassName="margin-right-xxs" />
        <a href="tel:+34926425780" className="link">
          926 42 57 80
        </a>
      </p>

      <p className="text-sm margin-top-lg">
        <Link to="/aviso-legal" className="color-black">
          Aviso legal
        </Link>
      </p>
    </div>
  </DiagonalSection>
)

export default Footer
