import React from "react"
import { Link, withPrefix } from "gatsby"
import logoViproman from "../../images/logo.jpg"

class Header extends React.Component {
  componentDidMount() {
    let header = document.createElement("script")
    header.src = `${withPrefix("scripts/header-script.js")}`
    header.id = "header-script-js"
    document.body.appendChild(header)

    let animMenuBtn = document.createElement("script")
    animMenuBtn.src = `${withPrefix("scripts/anim-menu-btn-script.js")}`
    animMenuBtn.id = "anim-menu-btn-script-js"
    document.body.appendChild(animMenuBtn)

    let drawer = document.createElement("script")
    drawer.src = `${withPrefix("scripts/drawer-script.js")}`
    drawer.id = "drawer-script-js"
    document.body.appendChild(drawer)

    let sidenavNavigation = document.createElement("script")
    sidenavNavigation.src = `${withPrefix(
      "scripts/sidenav-navigation-script.js"
    )}`
    sidenavNavigation.id = "sidenav-navigation-script-js"
    document.body.appendChild(sidenavNavigation)

    let dropdown = document.createElement("script")
    dropdown.src = `${withPrefix("scripts/dropdown-script.js")}`
    dropdown.id = "dropdown-script-js"
    document.body.appendChild(dropdown)

    let smoothScroll = document.createElement("script")
    smoothScroll.src = `${withPrefix("scripts/smooth-scroll-script.js")}`
    smoothScroll.id = "smooth-scroll-script-js"
    document.body.appendChild(smoothScroll)
  }

  componentWillUnmount() {
    document.getElementById("header-script-js").remove()
    document.getElementById("anim-menu-btn-script-js").remove()
    document.getElementById("drawer-script-js").remove()
    document.getElementById("sidenav-navigation-script-js").remove()
    document.getElementById("dropdown-script-js").remove()
    document.getElementById("smooth-scroll-script-js").remove()
  }

  render() {
    return (
      <header className="header-wrapper header-wrapper--sticky">
        <div className="header container">
          <div className="header__inner">
            <div className="header__logo">
              <Link to="/">
                <img src={logoViproman} alt="" />
              </Link>
            </div>
            <nav className="header__nav" role="navigation" aria-label="Main">
              <ul className="header__list">
                <li className="header__item">
                  <Link
                    to="/#promociones"
                    className="header__link js-tab-focus js-smooth-scroll">
                    <span>Promociones</span>
                  </Link>
                </li>
                <li className="header__item">
                  <Link to="/contacto" className="header__link js-tab-focus">
                    Contacto
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
