import React from "react"

const DiagonalSection = props => {
  return (
    <section
      class={`diagonal-section bg-key-l-5 ${props.className}`}
      style={{ backgroundImage: props.bgImage && `url(${props.bgImage})` }}>
      <div class="container max-width-sm padding-y-xxl">
        <div class="text-center">{props.children}</div>
      </div>
    </section>
  )
}

export default DiagonalSection
