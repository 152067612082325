import React from "react"
import Layout from "../components/Layout"

const LegalPage = () => {
  return (
    <Layout>
      <div className="container max-width-md margin-y-xl">
        <div className="text-component">
          <h2 className="text-md">
            Derechos de propiedad intelectual, industrial y otros derechos
          </h2>
          <p>
            El Usuario reconoce la titularidad de Viproman S.A. de todos los
            derechos de propiedad intelectual, industrial u otros derechos
            análogos sobre el Sitio Web www.viproman.com. A título meramente
            enunciativo, se entenderán incluidas las informaciones, mensajes,
            gráficos, dibujos, archivos de sonido o de imagen, fotografías,
            grabaciones, programas de ordenador, bases de datos, tecnología,
            equipos informáticos, know-how, marcas, signos distintivos, logos y,
            en general, cualquier clase de creación o material accesible a
            través de la web que sea de su propiedad, así como el propio Sitio
            Web.
          </p>
          <p>
            El usuario también reconoce y acepta que en el Sitio Web se
            encuentran accesibles contenidos y servicios propiedad de terceras
            personas, cuyos derechos se encuentran protegidos por la legislación
            en materia de propiedad intelectual, industrial, derechos de
            explotación del valor comercial y publicitario de la imagen y otros
            derechos análogos.
          </p>
          <p className="text-sm font-primary-medium">LSSI</p>
          <p>
            Dando cumplimiento al artículo 10 de la ley 34/2002 de Servicios de
            la Sociedad de la Información y Comercio Electrónico les facilitamos
            los siguientes datos que permiten la identificación del titular de
            este sitio web:
          </p>
          <p>Viproman S.A.</p>
          <p>Paseo de San Gregorio, 18</p>
          <p>13500 Puertollano (Ciudad Real)</p>
          <p>
            E-mail: <a href="mailto:info@viproman.com"> info@viproman.com</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default LegalPage
